import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ShortcutPageHeader from "../components/shortcutPageHeader"
import ShortcutTables from "../components/shortcutTables"

export default ({ pageContext: { logoURL, appName, shortcutGroups }}) => {
  const pageTitle = `Keyboard Shortcuts for ${appName}`
  return (
    <Layout>
      <SEO title={pageTitle} />
      <article className="post">
        <div className="uk-container uk-container-small">
          <ShortcutPageHeader logoURL={ logoURL } appName={ appName } />
          <ShortcutTables groups={shortcutGroups} />
        </div>
      </article>
    </Layout>
  )
}
