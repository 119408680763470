import React from "react"

const ShortcutPageHeader = ({ appName, logoURL }) => {
  return (
    <div className="cs-shortcut-page-header uk-child-width-expand" uk-grid="true">
      <div>
        <h1 className="uk-heading-primary">{ appName } Keyboard Shortcuts</h1>
      </div>
      <div>
        <img className="uk-align-right" src={ logoURL } alt={ appName } width="100" />
      </div>
    </div>

  )
}

export default ShortcutPageHeader