import React, { Component } from "react"
import MailchimpForm from "./mailchimpForm"

class NewsletterSignup extends Component {
  state = {
    emailSubmitted: false,
    errorMessage: ''
  }

  handleSuccessfulSubmission = () => {
    this.setState({ emailSubmitted: true })
  }

  handleFailedSubmission = (message) => {
    this.setState({ errorMessage: message })
  }

  render() {
    let callToAction
    if (this.state.errorMessage) {
      callToAction = (
        <><strong>Something didn't work.</strong> Check your email and try again!</>
      )
    } else {
      callToAction = (        
        <><strong>Need for speed?</strong> Get keyboard shortcut tips in your inbox:</>
      )
    }

    if (this.state.emailSubmitted) {
      return (
        <div className="cs-newsletter-signup">
          <div className="uk-padding-small">
            <div class="uk-flex uk-flex-center uk-flex-middle">
              <strong>Thank you!</strong>&nbsp;You are now subscribed.
            </div>
          </div>
        </div>
      )
    }
    return (
      <div className="cs-newsletter-signup">
        <div className="uk-padding-small">
          <div className="cs-newsletter-flex uk-flex uk-flex-center uk-flex-column uk-flex-middle">
            <div className="cs-newsletter-cta">
              { callToAction }
            </div>
            <div className="">
              <MailchimpForm 
                handleSuccessfulSubmission={this.handleSuccessfulSubmission}
                handleFailedSubmission={this.handleFailedSubmission} 
              /> 
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default NewsletterSignup
