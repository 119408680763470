/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { Component } from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import Header from "./header"
import NewsletterSignUp from "./newsletterSignup"
import "../css/cmdspace.scss"

class Layout extends Component {
  
  componentDidMount() {
    try {
      this.UIkit = require("uikit/dist/js/uikit");
      this.Icons = require("uikit/dist/js/uikit-icons");
      this.UIkit.use(this.Icons);
    } catch (e) {
      console.error(e);
    }
  }
  
  render() {
    return (
      <>
        <Header siteTitle={this.props.data.site.siteMetadata.title} />
        <NewsletterSignUp />
        <main>{this.props.children}</main>
      </>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default props => (
  <StaticQuery 
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }`
    }
    render={data => <Layout data={data} {...props} />}
  />
);
