import React from "react"
import NavList from "./navList"

const Nav = () => (
  <div> 
    <div className="uk-navbar-right uk-text-right uk-light">
      <NavList classes="uk-navbar-nav" />
    </div>
  </div>
)

export default Nav
