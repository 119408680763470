import React from "react"
import Shortcut from "./shortcut"

const ShortcutRow = ({ shortcut }) => {
  return (
    <tr>
      <td className="uk-width-1-2 cs-shortcut">
        <Shortcut shortcut={shortcut} />
      </td>
      <td className="uk-width-1-2">
        { shortcut.command }
      </td>
    </tr>
  )
}

const ShortcutGroupTable = ({group}) => {
  const shortcutRows = group.shortcuts.map((shortcut, i) => <ShortcutRow key={i} shortcut={shortcut} />)
  return (
    <>
      <h3 className="uk-margin-top">
        { group.title }
      </h3>
      <table className="uk-table uk-table-striped uk-table-hover uk-table-middle">
        <tbody>
          { shortcutRows }  
        </tbody>
      </table>
    </>
  )
}

const ShortcutTables = ({ groups }) => {
  return groups.map((group, i) => <ShortcutGroupTable key={i} group={group} />)
}

export default ShortcutTables