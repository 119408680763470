import React from "react"
import { Link } from "gatsby"

const NavList = ({ classes }) => (
    <ul className={classes}>
      <li>
        <Link to="/" activeClassName="uk-active">
          Home
        </Link>
      </li>
      <li>
        <a
          href="https://github.com/randomshortcuts/keyboard-shortcut-directory"
          className="ui-icon-link"
          target="_blank"
          uk-icon="icon: github"
          rel="noopener noreferrer">
        </a>
      </li>
      <li>
        <a
          href="https://twitter.com/randomshortcuts"
          className="ui-icon-link"
          target="_blank"
          uk-icon="icon: twitter"
          rel="noopener noreferrer">

        </a>
      </li>
    </ul>
)

export default NavList
