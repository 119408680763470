import { Link } from "gatsby"
import React from "react"
import logo from "../images/random-shortcuts-logo-white.svg"
import Nav from "./nav"

const Header = () => (
  <header className="uk-navbar-container">
    <div className="uk-container uk-container-small">
      <div className="uk-navbar" uk-navbar="true">
        <div className="uk-navbar-left">
          <Link to="/" className="uk-navbar-item uk-logo">
            <img src={logo} width="130" height="66" alt="Random Shortcuts Logo" />
          </Link>
        </div>
        <div className="uk-navbar-right uk-text-right uk-light">
          <Nav />
        </div>
      </div>
    </div>
  </header>
)

export default Header
