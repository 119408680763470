import React, { Component } from "react"
import addToMailchimp from "gatsby-plugin-mailchimp"

class MailchimpForm extends Component {
  state = {
    email: '',
  }

  _handleSubmit = async (e) => {
    e.preventDefault();
    const submissionResult = await addToMailchimp(this.state.email, {
      "group[3261][1]": "1",
      "group[3261][2]": "2",
      "group[3261][4]": "4",
      "group[3261][8]": "8",
      "group[3261][16]": "16",
      "group[3261][32]": "32",
      "group[3261][64]": "64",
      "group[3261][128]": "128",
      "group[3261][256]": "256"
    })
    if (submissionResult.result === 'success') {
      this.props.handleSuccessfulSubmission()
    } else {
      this.props.handleFailedSubmission(submissionResult.msg)
    }
  }

  onEmailChange = (e) => {
    const email = e.target.value;
    this.setState({ email })
  }

  render() {
    return (
      <form className="cs-newsletter-form uk-flex uk-flex-center uk-flex-column uk-flex-middle" onSubmit={this._handleSubmit}>
        <div>
          <input
            type="email"
            name="EMAIL"
            className="uk-input uk-width-medium"
            id="mce-EMAIL"
            placeholder="Email Address"
            onChange={this.onEmailChange}
          />
        </div>
        <div>
          <input
            type="submit"
            value="Subscribe"
            name="subscribe"
            id="mc-embedded-subscribe"
            className="uk-margin-left uk-button cs-button" 
          />
        </div>
      </form>
    )
  }
}

export default MailchimpForm