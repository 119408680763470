import React from "react"

const ShortcutStep = ({ step, index }) => {
  let classNames = []
  if (step.type === "key") {
    classNames.push("cs-keyboard-key")
  }
  
  const Step = ({ step }) => (
    <span className={classNames.join(' ')}>
      { step.value }
    </span>)
  
  if (index === 0) {
    return <Step step={step} />
  } else {
    return (
      <>&nbsp;<Step step={step} /></>
    )
  }
}

export default ({ shortcut }) => {
  return shortcut.steps.map((step, i) => <ShortcutStep key={i} index={i} step={step} />)
}